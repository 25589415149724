import React, { useContext } from 'react';
import Layout from "../components/layout";
import fennecPath from "../img/thirdPage/fennec.svg";
import ownTeamPath from "../img/thirdPage/multiple.svg";
import outSourcePath from "../img/thirdPage/construction.svg";
import linkedIcon from '../img/linkedIcon.svg';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { ModalContext } from '../context/modalContext';
import { graphql } from "gatsby";
import Img from 'gatsby-image';
import Fade from 'react-reveal/Fade';
import SEO from '../components/seo';


export default ({ data }) => {

  const { onHide } = useContext(ModalContext);

  return (
    <Layout>
			<SEO title="About" image="/img/AboutUs.png" description="Get to know the team and see how Fennec stacks up against others" />
      <div className="section31" id="whyFennec">
        <div className="paraGroup31">
          <Fade top distance={'10%'} duration={2500}>
            <div>
              <p className="p1 font-f5 font-sz-28 pb-2" data-1 >{data.fennecJson.about.data1}</p>
              <p className="p2 font-f6 font-sz-75" data-2 >{data.fennecJson.about.data2}</p>
            </div>
          </Fade>
          <p className="p3 font-inter-bold font-sz-24" data-3 >{data.fennecJson.about.data3}</p>
          <p className="p3 font-inter-bold font-sz-24" data-4 >{data.fennecJson.about.data4}</p>
          <p className="p3 font-inter-bold font-sz-24" data-5 >{data.fennecJson.about.data5}</p>
          <p className="p3 font-inter-bold font-sz-24" data-6 >{data.fennecJson.about.data6}</p>
          <p className="p3 font-inter-bold font-sz-24" data-7 id="resource">{data.fennecJson.about.data7}</p>
        </div>
      </div>
      <div className="section32">
        <div className="xsTabRow">
          <Tabs defaultActiveKey="fennec" id="uncontrolled-tab-example">
            <Tab eventKey="team" title="OWN TEAM">
              <div className="row justify-content-center xsCardsGroup">
                <div className="col-12 card3">
                  <div className="cardHeader">
                    <img src={ownTeamPath} className="imgHeader" alt="" />
                    <p className="textHeader">OWN TEAM</p>
                  </div>
                  <table className="table table-striped text-center">
                    <tbody>
                      <tr><td className="font-f4 font-sz-22" data-8 >{data.fennecJson.about.firstColumn.data8}</td><td className="text-center font-f4 font-sz-22" data-28 >{data.fennecJson.about.ownTeamColumn.data28}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-9 >{data.fennecJson.about.firstColumn.data9}</td><td className="text-center font-f4 font-sz-22" data-29 >{data.fennecJson.about.ownTeamColumn.data29}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-10 >{data.fennecJson.about.firstColumn.data10}</td><td className="text-center font-f4 font-sz-22" data-30 >{data.fennecJson.about.ownTeamColumn.data30}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-11 >{data.fennecJson.about.firstColumn.data11}</td><td className="text-center font-f4 font-sz-22" data-31 >{data.fennecJson.about.ownTeamColumn.data31}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-12 >{data.fennecJson.about.firstColumn.data12}</td><td className="text-center font-f4 font-sz-22" data-32 >{data.fennecJson.about.ownTeamColumn.data32}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-13 >{data.fennecJson.about.firstColumn.data13}</td><td className="text-center font-f4 font-sz-22" data-33 >{data.fennecJson.about.ownTeamColumn.data33}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-14 >{data.fennecJson.about.firstColumn.data14}</td><td className="text-center font-f4 font-sz-22" data-34 >{data.fennecJson.about.ownTeamColumn.data34}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-15 >{data.fennecJson.about.firstColumn.data15}</td><td className="text-center font-f4 font-sz-22" data-35 >{data.fennecJson.about.ownTeamColumn.data35}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-16 >{data.fennecJson.about.firstColumn.data16}</td><td className="text-center font-f4 font-sz-22" data-36 >{data.fennecJson.about.ownTeamColumn.data36}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-17 >{data.fennecJson.about.firstColumn.data17}</td><td className="text-center font-f4 font-sz-22" data-37 >{data.fennecJson.about.ownTeamColumn.data37}</td></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>

            <Tab eventKey="fennec" title="FENNEC">
              <div className="row justify-content-center xsCardsGroup">
                <div className="col-12 card3">
                  <div className="cardHeader">
                    <img src={fennecPath} className="imgHeader" alt="" />
                    <p className="textHeader">FENNEC</p>
                  </div>
                  <table className="table table-striped text-center">
                    <tbody>
                      <tr><td className="font-f4 font-sz-22" data-8 >{data.fennecJson.about.firstColumn.data8}</td><td className="sCol text-center font-f4 font-sz-22" data-18 >{data.fennecJson.about.fennecColumn.data18}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-9 >{data.fennecJson.about.firstColumn.data9}</td><td className="sCol text-center font-f4 font-sz-22" data-19>{data.fennecJson.about.fennecColumn.data19}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-10 >{data.fennecJson.about.firstColumn.data10}</td><td className="sCol text-center font-f4 font-sz-22" data-20 >{data.fennecJson.about.fennecColumn.data20}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-11 >{data.fennecJson.about.firstColumn.data11}</td><td className="sCol text-center font-f4 font-sz-22" data-21 >{data.fennecJson.about.fennecColumn.data21}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-12 >{data.fennecJson.about.firstColumn.data12}</td><td className="sCol text-center font-f4 font-sz-22" data-22 >{data.fennecJson.about.fennecColumn.data22}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-13 >{data.fennecJson.about.firstColumn.data13}</td><td className="sCol text-center font-f4 font-sz-22" data-23 >{data.fennecJson.about.fennecColumn.data23}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-14 >{data.fennecJson.about.firstColumn.data14}</td><td className="sCol text-center font-f4 font-sz-22" data-24 >{data.fennecJson.about.fennecColumn.data24}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-15 >{data.fennecJson.about.firstColumn.data15}</td><td className="sCol text-center font-f4 font-sz-22" data-25 >{data.fennecJson.about.fennecColumn.data25}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-16 >{data.fennecJson.about.firstColumn.data16}</td><td className="sCol text-center font-f4 font-sz-22" data-26 >{data.fennecJson.about.fennecColumn.data26}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-17 >{data.fennecJson.about.firstColumn.data17}</td><td className="sCol text-center font-f4 font-sz-22" data-27 >{data.fennecJson.about.fennecColumn.data27}</td></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>

            <Tab eventKey="outsource" title="OUTSOURCE">
              <div className="row justify-content-center xsCardsGroup">
                <div className="col-12 card3">
                  <div className="cardHeader">
                    <img src={outSourcePath} className="imgHeader" alt="" />
                    <p className="textHeader">OUTSOURCE</p>
                  </div>
                  <table className="table table-striped text-center">
                    <tbody>
                      <tr><td className="font-f4 font-sz-22" data-8 >{data.fennecJson.about.firstColumn.data8}</td><td className="text-center font-f4 font-sz-22" data-38 >{data.fennecJson.about.outSourceColumn.data38}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-9 >{data.fennecJson.about.firstColumn.data9}</td><td className="text-center font-f4 font-sz-22" data-39 >{data.fennecJson.about.outSourceColumn.data39}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-10 >{data.fennecJson.about.firstColumn.data10}</td><td className="text-center font-f4 font-sz-22" data-40 >{data.fennecJson.about.outSourceColumn.data40}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-11 >{data.fennecJson.about.firstColumn.data11}</td><td className="text-center font-f4 font-sz-22" data-41 >{data.fennecJson.about.outSourceColumn.data41}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-12 >{data.fennecJson.about.firstColumn.data12}</td><td className="text-center font-f4 font-sz-22" data-42 >{data.fennecJson.about.outSourceColumn.data42}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-13 >{data.fennecJson.about.firstColumn.data13}</td><td className="text-center font-f4 font-sz-22" data-43 >{data.fennecJson.about.outSourceColumn.data43}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-14 >{data.fennecJson.about.firstColumn.data14}</td><td className="text-center font-f4 font-sz-22" data-44 >{data.fennecJson.about.outSourceColumn.data44}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-15 >{data.fennecJson.about.firstColumn.data15}</td><td className="text-center font-f4 font-sz-22" data-45 >{data.fennecJson.about.outSourceColumn.data45}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-16 >{data.fennecJson.about.firstColumn.data16}</td><td className="text-center font-f4 font-sz-22" data-46 >{data.fennecJson.about.outSourceColumn.data46}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-17 >{data.fennecJson.about.firstColumn.data17}</td><td className="text-center font-f4 font-sz-22" data-47 >{data.fennecJson.about.outSourceColumn.data47}</td></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
        <div className="tabRow">
          <Tabs defaultActiveKey="fennec" id="uncontrolled-tab-example">
            <Tab eventKey="team" title="OWN TEAM">
              <div className="row justify-content-center mr-0 mr-md-3 mr-lg-5 mt-5">
                <div className="col-5 col-lg-4 firstCol">
                  <table className="table text-right">
                    <tbody>
                      <tr><td className="font-f4 font-sz-22" data-8>{data.fennecJson.about.firstColumn.data8}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-9>{data.fennecJson.about.firstColumn.data9}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-10>{data.fennecJson.about.firstColumn.data10}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-11>{data.fennecJson.about.firstColumn.data11}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-12>{data.fennecJson.about.firstColumn.data12}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-13>{data.fennecJson.about.firstColumn.data13}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-14>{data.fennecJson.about.firstColumn.data14}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-15>{data.fennecJson.about.firstColumn.data15}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-16>{data.fennecJson.about.firstColumn.data16}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-17>{data.fennecJson.about.firstColumn.data17}</td></tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-6 col-lg-5 card3">
                  <div className="cardHeader">
                    <img src={ownTeamPath} className="imgHeader" alt="" />
                    <p className="textHeader font-f5 font-sz-24">OWN TEAM</p>
                  </div>
                  <table className="table table-striped text-center">
                    <tbody>
                      <tr><td className="font-f4 font-sz-22" data-28 >{data.fennecJson.about.ownTeamColumn.data28}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-29 >{data.fennecJson.about.ownTeamColumn.data29}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-30 >{data.fennecJson.about.ownTeamColumn.data30}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-31 >{data.fennecJson.about.ownTeamColumn.data31}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-32 >{data.fennecJson.about.ownTeamColumn.data32}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-33 >{data.fennecJson.about.ownTeamColumn.data33}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-34 >{data.fennecJson.about.ownTeamColumn.data34}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-35 >{data.fennecJson.about.ownTeamColumn.data35}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-36 >{data.fennecJson.about.ownTeamColumn.data36}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-37 >{data.fennecJson.about.ownTeamColumn.data37}</td></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>


            <Tab eventKey="fennec" title="FENNEC">
              <div className="row justify-content-center  mr-0 mr-md-3 mr-lg-5 mt-5">
                <div className="col-5 col-lg-4 firstCol">
                  <table className="table text-right">
                    <tbody>
                      <tr><td className="font-f4 font-sz-22" data-8>{data.fennecJson.about.firstColumn.data8}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-9>{data.fennecJson.about.firstColumn.data9}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-10>{data.fennecJson.about.firstColumn.data10}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-11>{data.fennecJson.about.firstColumn.data11}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-12>{data.fennecJson.about.firstColumn.data12}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-13>{data.fennecJson.about.firstColumn.data13}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-14>{data.fennecJson.about.firstColumn.data14}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-15>{data.fennecJson.about.firstColumn.data15}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-16>{data.fennecJson.about.firstColumn.data16}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-17>{data.fennecJson.about.firstColumn.data17}</td></tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-6 col-lg-5 card3 secondCol">
                  <div className="cardHeader">
                    <img src={fennecPath} className="imgHeader" alt="" />
                    <p className="textHeader font-f5 font-sz-24">FENNEC</p>
                  </div>
                  <table className="table table-striped text-center">
                    <tbody>
                      <tr><td className="font-f4 font-sz-22" data-18 >{data.fennecJson.about.fennecColumn.data18}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-19 >{data.fennecJson.about.fennecColumn.data19}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-20 >{data.fennecJson.about.fennecColumn.data20}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-21 >{data.fennecJson.about.fennecColumn.data21}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-22 >{data.fennecJson.about.fennecColumn.data22}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-23 >{data.fennecJson.about.fennecColumn.data23}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-24 >{data.fennecJson.about.fennecColumn.data24}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-25 >{data.fennecJson.about.fennecColumn.data25}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-26 >{data.fennecJson.about.fennecColumn.data26}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-27 >{data.fennecJson.about.fennecColumn.data27}</td></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>

            <Tab eventKey="outsource" title="OUTSOURCE">
              <div className="row justify-content-center mr-0 mr-md-3 mr-lg-5 mt-5">
                <div className="col-5 col-lg-4 firstCol">
                  <table className="table text-right">
                    <tbody>
                      <tr><td className="font-f4 font-sz-22" data-8>{data.fennecJson.about.firstColumn.data8}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-9>{data.fennecJson.about.firstColumn.data9}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-10>{data.fennecJson.about.firstColumn.data10}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-11>{data.fennecJson.about.firstColumn.data11}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-12>{data.fennecJson.about.firstColumn.data12}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-13>{data.fennecJson.about.firstColumn.data13}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-14>{data.fennecJson.about.firstColumn.data14}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-15>{data.fennecJson.about.firstColumn.data15}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-16>{data.fennecJson.about.firstColumn.data16}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-17>{data.fennecJson.about.firstColumn.data17}</td></tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-6 col-lg-5 card3">
                  <div className="cardHeader">
                    <img src={outSourcePath} className="imgHeader" alt="" />
                    <p className="textHeader font-f5 font-sz-24">OUTSOURCE</p>
                  </div>
                  <table className="table table-striped text-center">
                    <tbody>
                      <tr><td className="font-f4 font-sz-22" data-38 >{data.fennecJson.about.outSourceColumn.data38}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-39 >{data.fennecJson.about.outSourceColumn.data39}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-40 >{data.fennecJson.about.outSourceColumn.data40}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-41 >{data.fennecJson.about.outSourceColumn.data41}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-42 >{data.fennecJson.about.outSourceColumn.data42}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-43 >{data.fennecJson.about.outSourceColumn.data43}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-44 >{data.fennecJson.about.outSourceColumn.data44}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-45 >{data.fennecJson.about.outSourceColumn.data45}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-46 >{data.fennecJson.about.outSourceColumn.data46}</td></tr>
                      <tr><td className="font-f4 font-sz-22" data-47 >{data.fennecJson.about.outSourceColumn.data47}</td></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
        <div className="row justify-content-center card3Group">
          <div className="col firstCol">
            <table className="table text-right">
              <tbody>
                <tr><td className="firstTr font-f4 font-sz-22" data-8 >{data.fennecJson.about.firstColumn.data8}</td></tr>
                <tr><td className="font-f4 font-sz-22" data-9 >{data.fennecJson.about.firstColumn.data9}</td></tr>
                <tr><td className="font-f4 font-sz-22" data-10 >{data.fennecJson.about.firstColumn.data10}</td></tr>
                <tr><td className="font-f4 font-sz-22" data-11 >{data.fennecJson.about.firstColumn.data11}</td></tr>
                <tr><td className="font-f4 font-sz-22" data-12 >{data.fennecJson.about.firstColumn.data12}</td></tr>
                <tr><td className="font-f4 font-sz-22" data-13 >{data.fennecJson.about.firstColumn.data13}</td></tr>
                <tr><td className="font-f4 font-sz-22" data-14 >{data.fennecJson.about.firstColumn.data14}</td></tr>
                <tr><td className="font-f4 font-sz-22" data-15 >{data.fennecJson.about.firstColumn.data15}</td></tr>
                <tr><td className="font-f4 font-sz-22" data-16 >{data.fennecJson.about.firstColumn.data16}</td></tr>
                <tr><td className="font-f4 font-sz-22" data-17 >{data.fennecJson.about.firstColumn.data17}</td></tr>
              </tbody>
            </table>
          </div>
          <Fade>
            <div className="col card3  secondCol">
              <div className="cardHeader">
                <img src={fennecPath} className="imgHeader" alt="" />
                <p className="textHeader font-f6 font-sz-24">FENNEC</p>
              </div>
              <table className="table table-striped text-center">
                <tbody>
                  <tr><td className="font-f4 font-sz-22" data-18 >{data.fennecJson.about.fennecColumn.data18}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-19 >{data.fennecJson.about.fennecColumn.data19}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-20 >{data.fennecJson.about.fennecColumn.data20}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-21 >{data.fennecJson.about.fennecColumn.data21}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-22 >{data.fennecJson.about.fennecColumn.data22}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-23 >{data.fennecJson.about.fennecColumn.data23}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-24 >{data.fennecJson.about.fennecColumn.data24}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-25 >{data.fennecJson.about.fennecColumn.data25}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-26 >{data.fennecJson.about.fennecColumn.data26}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-27 >{data.fennecJson.about.fennecColumn.data27}</td></tr>
                </tbody>
              </table>
            </div>
            <div className="col card3">
              <div className="cardHeader">
                <img src={ownTeamPath} className="imgHeader" alt="" />
                <p className="textHeader font-f6 font-sz-24">OWN TEAM</p>
              </div>
              <table className="table table-striped text-center">
                <tbody>
                  <tr><td className="font-f4 font-sz-22" data-28 >{data.fennecJson.about.ownTeamColumn.data28}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-29 >{data.fennecJson.about.ownTeamColumn.data29}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-30 >{data.fennecJson.about.ownTeamColumn.data30}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-31 >{data.fennecJson.about.ownTeamColumn.data31}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-32 >{data.fennecJson.about.ownTeamColumn.data32}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-33 >{data.fennecJson.about.ownTeamColumn.data33}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-34 >{data.fennecJson.about.ownTeamColumn.data34}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-35 >{data.fennecJson.about.ownTeamColumn.data35}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-36 >{data.fennecJson.about.ownTeamColumn.data36}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-37 >{data.fennecJson.about.ownTeamColumn.data37}</td></tr>
                </tbody>
              </table>
            </div>
            <div className="col card3">
              <div className="cardHeader">
                <img src={outSourcePath} className="imgHeader" alt="" />
                <p className="textHeader font-f6 font-sz-24">OUTSOURCE</p>
              </div>
              <table className="table table-striped text-center">
                <tbody>
                  <tr><td className="font-f4 font-sz-22" data-38 >{data.fennecJson.about.outSourceColumn.data38}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-39 >{data.fennecJson.about.outSourceColumn.data39}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-40 >{data.fennecJson.about.outSourceColumn.data40}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-41 >{data.fennecJson.about.outSourceColumn.data41}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-42 >{data.fennecJson.about.outSourceColumn.data42}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-43 >{data.fennecJson.about.outSourceColumn.data43}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-44 >{data.fennecJson.about.outSourceColumn.data44}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-45 >{data.fennecJson.about.outSourceColumn.data45}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-46 >{data.fennecJson.about.outSourceColumn.data46}</td></tr>
                  <tr><td className="font-f4 font-sz-22" data-47 >{data.fennecJson.about.outSourceColumn.data47}</td></tr>
                </tbody>
              </table>
            </div>
          </Fade>
        </div>
        <div className="padding-horizontal" id="team">
          <div className="paraGroup32">
            <Fade left distance={'20%'}>
              <p className="p4 font-f6 font-sz-16" data-48 >{data.fennecJson.about.data48}</p>
              <p className="p5 font-f4 font-sz-44" data-49 >{data.fennecJson.about.data49}</p>
            </Fade>
            <div className="row row32">
              <Fade left distance={'20%'}>
                <div className="col-12 col-lg-6">
                  <p className="font-more font-sz-20" data-50 >{data.fennecJson.about.data50}</p>
                </div>
              </Fade>
              <Fade right distance={'20%'}>
                <div className="col-12 col-lg-6">
                  <p className="p7 font-more font-sz-20" data-51 >{data.fennecJson.about.data51}</p>
                  <p className="font-more font-sz-20" data-52 >{data.fennecJson.about.data52}</p>
                </div>
              </Fade>
            </div>
          </div>
          <div className="pt-5">
            <div className="row imgRow">
              <Fade left distance={'20%'}>
                <div className="col-12 col-lg-6 imgRowCol">
                  <Img className="TeamImages" fluid={data.ayhamImg.childImageSharp.fluid} />
                </div>
              </Fade>
              <Fade right distance={'20%'}>
                <div className="col-12 col-lg-6 imgRowCol">
                  <p className="p8 font-f6 font-sz-14 text-uppercase mt-3 mt-lg-0" data-53 >{data.fennecJson.about.data53}</p>
                  <p className="font-f4 font-sz-44 text-uppercase" data-54 >{data.fennecJson.about.data54}</p>
                  <p className="font-more font-sz-20" data-55 >{data.fennecJson.about.data55}</p>
                  <a href="https://www.linkedin.com/in/ayhamg/" target="_blank" rel="noopener noreferrer" ><p className="font-more font-sz-20 mt-5"><img src={linkedIcon} alt="Linked in" className="BioIcon mr-3" /> LinkedIn Profile </p></a>
                </div>
              </Fade>
            </div>
            <div className="row imgRow">
              <Fade left distance={'20%'}>
                <div className="col-12 col-lg-6 imgRowCol order-lg-2">
                  <Img className="TeamImages" fluid={data.saedImg.childImageSharp.fluid} />
                </div>
              </Fade>
              <Fade right distance={'20%'}>
                <div className="col-12 col-lg-6 imgRowCol order-lg-1">
                  <p className="p8 font-f6 font-sz-14 text-uppercase mt-3 mt-lg-0" data-56 >{data.fennecJson.about.data56}</p>
                  <p className="font-f4 font-sz-44 text-uppercase" data-57 >{data.fennecJson.about.data57}</p>
                  <p className="font-more font-sz-20" data-58 >{data.fennecJson.about.data58}</p>
                  <p className="font-more font-sz-20" data-582 >{data.fennecJson.about.data582}</p>
                  <a href="https://www.linkedin.com/in/saedghorani/" target="_blank" rel="noopener noreferrer" ><p className="font-more font-sz-20 mt-5"><img src={linkedIcon} alt="Linked in" className="BioIcon mr-3" /> LinkedIn Profile </p></a>
                </div>
              </Fade>
            </div>
            <div className="row imgRow mb-5">
              <Fade left distance={'20%'}>
                <div className="col-12 col-lg-6 imgRowCol">
                  <Img className="TeamImages" fluid={data.haniImg.childImageSharp.fluid} />
                </div>
              </Fade>
              <Fade right distance={'20%'}>
                <div className="col-12 col-lg-6 imgRowCol">
                  <p className="p8 font-f6 font-sz-14 text-uppercase mt-3 mt-lg-0" data-59 >{data.fennecJson.about.data59}</p>
                  <p className="font-f4 font-sz-44 text-uppercase" data-60 >{data.fennecJson.about.data60}</p>
                  <p className="font-more font-sz-20" data-61 >{data.fennecJson.about.data61}</p>
                  <p className="font-more font-sz-20" data-612 >{data.fennecJson.about.data612}</p>
                  <a href="https://www.linkedin.com/in/hani-abushaer/" target="_blank" rel="noopener noreferrer" ><p className="font-more font-sz-20 mt-5"><img src={linkedIcon} alt="Linked in" className="BioIcon mr-3" /> LinkedIn Profile </p></a>
                </div>
              </Fade>
            </div>
            <div className="row imgRow mb-5">
              <Fade left distance={'20%'}>
                <div className="col-12 col-lg-6 imgRowCol order-lg-2">
                  <Img className="TeamImages" fluid={data.samiImg.childImageSharp.fluid} />
                </div>
              </Fade>
              <Fade right distance={'20%'}>
                <div className="col-12 col-lg-6 imgRowCol order-lg-1 mt-3 mt-lg-0">
                  <p className="p8 font-f6 font-sz-14 text-uppercase" data-62 >{data.fennecJson.about.data62}</p>
                  <p className="font-f4 font-sz-44 text-uppercase" data-63 >{data.fennecJson.about.data63}</p>
                  <p className="font-more font-sz-20"data-64 >{data.fennecJson.about.data64}</p>
                  <p className="font-more font-sz-20"data-642 >{data.fennecJson.about.data642}</p>
                  <a href="https://www.linkedin.com/in/sami-sammour/" target="_blank" rel="noopener noreferrer" ><p className="font-more font-sz-20 mt-5"><img src={linkedIcon} alt="Linked in" className="BioIcon mr-3" /> LinkedIn Profile </p></a>
                </div>
              </Fade>
            </div>
            <div className="row imgRow mb-5">
              <Fade left distance={'20%'}>
                <div className="col-12 col-lg-6 imgRowCol">
                  <Img className="TeamImages" fluid={data.onurImg.childImageSharp.fluid} />
                </div>
              </Fade>
              <Fade right distance={'20%'}>
                <div className="col-12 col-lg-6 imgRowCol">
                  <p className="p8 font-f6 font-sz-14 text-uppercase mt-3 mt-lg-0" data-65 >{data.fennecJson.about.data65}</p>
                  <p className="font-f4 font-sz-44 text-uppercase" data-66 >{data.fennecJson.about.data66}</p>
                  <p className="font-more font-sz-20"data-67 >{data.fennecJson.about.data67}</p>
                  <p className="font-more font-sz-20"data-672 >{data.fennecJson.about.data672}</p>
                  <a href="https://www.linkedin.com/in/onur-witt/" target="_blank" rel="noopener noreferrer" ><p className="font-more font-sz-20 mt-5"><img src={linkedIcon} alt="Linked in" className="BioIcon mr-3" /> LinkedIn Profile </p></a>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <div className="section33">
        <div className="paraWrapper5">
          <p className="para51 font-f4 font-sz-60" data-62 >{data.fennecJson.about.data68}</p>
          <button className="button51 font-f6 font-sz-16" onClick={() => onHide(true)}>GET IN TOUCH</button>
        </div>
      </div>
    </Layout>
  )
}




export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    ayhamImg: file(relativePath: { eq: "thirdPage/ayham.png" }) {
      ...fluidImage
    }
    saedImg: file(relativePath: { eq: "thirdPage/saed.png" }) {
      ...fluidImage
    }
    haniImg: file(relativePath: { eq: "thirdPage/hani.png" }) {
      ...fluidImage
    }
    samiImg: file(relativePath: { eq: "thirdPage/sami.png" }) {
      ...fluidImage
    }
    onurImg: file(relativePath: { eq: "thirdPage/onur.png" }) {
      ...fluidImage
    }
    fennecJson {
      about {
        data1,
        data2,
        data3,
        data4,
        data5,
        data6,
        data7,
        firstColumn{
          data8,
          data9,
          data10,
          data11,
          data12,
          data13,
          data14,
          data15,
          data16,
          data17
        },
        fennecColumn{
          data18,
          data19,
          data20,
          data21,
          data22,
          data23,
          data24,
          data25,
          data26,
          data27
        },
        ownTeamColumn{
          data28,
          data29,
          data30,
          data31,
          data32,
          data33,
          data34,
          data35,
          data36,
          data37
        },
        outSourceColumn{
          data38,
          data39,
          data40,
          data41,
          data42,
          data43,
          data44,
          data45,
          data46,
          data47
        }
        data48,
        data49,
        data50,
        data51,
        data52,
        data53,
        data54,
        data55,
        data56,
        data57,
        data58,
        data582,
        data59,
        data60,
        data61,
        data612,
        data62,
        data63,
        data64,
        data642,
        data65,
        data66,
        data67,
        data672,
        data68,
      }
    }
  }
  
`;
